function roadmap2022() {
    var elemButton = document.getElementById("is-button");
    var elemRoadmap = document.getElementById("is-roadmap");
    var elemTitle = document.getElementById("is-title");
    elemButton.classList.add("button-2023-wrap");
    elemButton.classList.remove("button-2022-wrap");
    elemRoadmap.classList.add("roadmap-2023-wrap");
    elemRoadmap.classList.remove("roadmap-2022-wrap");
    elemTitle.classList.add("title-2023-wrap");
    elemTitle.classList.remove("title-2022-wrap");
}
function roadmap2023() {
    var elemButton = document.getElementById("is-button");
    var elemRoadmap = document.getElementById("is-roadmap");
    var elemTitle = document.getElementById("is-title");
    elemButton.classList.add("button-2022-wrap");
    elemButton.classList.remove("button-2023-wrap");
    elemRoadmap.classList.add("roadmap-2022-wrap");
    elemRoadmap.classList.remove("roadmap-2023-wrap");
    elemTitle.classList.add("title-2022-wrap");
    elemTitle.classList.remove("title-2023-wrap");
}

var owl = $('.unique-carousel');
owl.owlCarousel({
    loop:false,
    autoplay: true,
    nav:false,
    responsive:{
        0:{
            items:1,
            stagePadding: 0,
            margin: 15
        },
        600:{
            items:1,
            stagePadding: 0,
            margin: 15
        },
        1000:{
            items:2,
            margin:93,
            stagePadding: 200
        }
    }
});
owl.on('mousewheel', '.owl-stage', function (e) {
    if (e.deltaY>0) {
        owl.trigger('next.owl');
    } else {
        owl.trigger('prev.owl');
    }
    e.preventDefault();
});

AOS.init({
    once: true,
});